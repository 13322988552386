.site-title {
  align-items: flex-start;
  display: flex;
  flex-direction: column;

  * {
    white-space: nowrap;
  }

  a, a:visited {
    font-weight: 700;
    // text-transform: uppercase;
    text-decoration: none;
    color: inherit;
  }
}
