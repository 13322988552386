// All the var juice

// Colors
@mixin light-mode {
  --theme-name: "light";
  --bg: white;
  --fg: var(--gray-800);
  --gray-50:  #f9fafb;
  --gray-100: #f3f4f6;
  --gray-200: #e5e7eb;
  --gray-300: #d1d5db;
  --gray-400: #9ca3af;
  --gray-500: #6b7280;
  --gray-600: #4b5563;
  --gray-700: #374151;
  --gray-800: #1f2937;
  --gray-900: #111827;
}

@mixin dark-mode {
  --theme-name: "dark";
  --bg:  #000;
  --fg: var(--gray-800);
  --gray-900: #f9fafb;
  --gray-800: #f3f4f6;
  --gray-700: #e5e7eb;
  --gray-600: #d1d5db;
  --gray-500: #9ca3af;
  --gray-400: #6b7280;
  --gray-300: #4b5563;
  --gray-200: #374151;
  --gray-100: #1f2937;
  --gray-50:  #111827;
}

:root {
  @include light-mode;
  @import "syntax-light";
}

@media(prefers-color-scheme: dark) {
  :root {
    @include dark-mode;
    @import "syntax-dark";
  }
}


// Breakpoints
$bp-sm: 600px;
$bp-md: 960px;
$bp-lg: 1280px;

// Other sizes
$sizes: (
  "zero": 0rem,
  "xs": 0.4rem,
  "sm": 1.6rem,
  "md": 2.4rem,
  "lg": 4.2rem
);

:root {
  --header-height: clamp(10rem, calc(6rem + 6vw), 14rem);
}
