hr {
  margin: 4rem 0;
  border: 0;
  border-top: 1px solid var(--gray-200);
}

.footer {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  gap: 1em;
  font-size: 0.82rem;

  @include media($bp-md) {
    flex-direction: row;
    gap: 2em;
  }
}
