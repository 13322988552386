.header {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  margin: 2rem 0;
  flex-shrink: 0;

  @include media($bp-md) {
    margin: 0;
    height: var(--header-height);
    justify-content: center;
    grid-area: 1 / 1;

    .has-index & {
      position: fixed;
    }
  }
}
