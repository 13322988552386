// Mixin for generating media queries
//
// Provided a single value, the mixin will output a media query using the
// min-width rule. When provided two values, the first will be the rule, and the
// second will be the value.
//
// Examples:
//
// input:
// @include media(100px) {}
//
// output:
// @media screen and (min-width: 100px) {}
//
// input:
// @include media(max-width 100px) {}
//
// output:
// @media screen and (max-width: 100px) {}

$_default-feature: min-width;

@mixin media($query: $feature $value) {
  @if length($query) == 1 {
    @media screen and ($_default-feature: nth($query, 1)) {
      @content;
    }
  } @else {
    @media screen and (nth($query, 1): nth($query, 2)) {
      @content;
    }
  }
}
