@import "reset";
@import "variables";

// mixin
@import "media";


@import "font-face";
@import "typography";
@import "margin";

@import "components/header";
@import "components/wide-image";
@import "components/site-title";
@import "components/nav";
@import "components/base-layout";
@import "components/page-content";
@import "components/link-index";
@import "components/small-date";
@import "components/footer";
@import "components/sidebar";

// TODO: Put this somewhere
input {
  width: 100%;
  padding: 0.25em 0.5em;
  border-radius: 5px;
  border: 1px solid var(--gray-400);
  background-color: inherit;
  color: inherit;

  &::placeholder {
    color: var(--gray-600);
  }
}

input:focus {
  border-color: var(--gray-600);
  outline: none;
}
