.nav {
  display: flex;
  align-items: center;
  gap: .5em;
  margin-bottom: 3rem;

  @include media($bp-md) {
    // gap: 1em;
    justify-content: flex-end;
    height: var(--header-height);
    margin: 0;
    grid-column: 2;
    grid-row: 1;
  }
}

.nav__link {
  border: 1px solid var(--gray-300);
  border-radius: 4px;
  color: inherit;
  display: block;
  padding: 0 0.4em;
  height: 2rem;
  display: flex;
  align-items: center;
  text-decoration: none;
  white-space: nowrap;
}

.nav__link--active {
  border-color: transparent;
  background-color: var(--gray-200);

  &, &:visited {
    color: inherit;
  }

  &:hover {
  }
}

.inline-svg svg{
  fill: currentColor;
}
