.base-layout {
  --margin: 1rem;
  margin: 0 auto;
  display: flex;
  gap: 0 4rem;
  flex-direction: column;
  padding: 0 var(--margin);
  width: 100%;
  max-width: 77rem;

  @include media($bp-sm) {
    --margin: 4rem;
  }

  @include media($bp-md) {
    display: grid;
    grid-template-columns: 16rem 1fr;
    grid-template-rows: var(--header-height) 1fr;
  }
}
