.page-content {
  width: 100%;
  padding-bottom: 10rem;
  grid-column-start: 1;
  grid-column-end: -1;

  @include media($bp-md) {
    display: grid;

    .has-index & {
      justify-items: center;
      grid-row: 2;
      grid-column-start: 2;
    }
  }

  article {
    :is(.section) & {
      display: none;

      @include media($bp-md) {
        display: block;
      }
    }
  }
}
