.sidebar {
  @include media($bp-md) {
    display: flex;
    flex-direction: column;
    grid-row: span 2;

    .has-index & {
      height: 100vh;
      position: sticky;
      top: 0;
    }
  }
}
