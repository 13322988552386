html {
  font-size: 1rem;

  @include media($bp-lg) {
    font-size: 1.125rem;
  }
}

body {
  font-family: "IBM Plex Sans", sans-serif;
  line-height: 1.66;
  color: var(--fg);
  background-color: var(--bg);
}

pre, code {
  font-family: "IBM Plex Mono", monospace;
}

h1 {
  font-size: 2em;
  font-family: "Lora", serif;

  .notes & {
    font-family: inherit;
  }
}

h1, h2, h3 {
  margin: 1em 0;
  line-height: 1.2;

  &:first-child {
    margin-top: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
}

blockquote {
  padding: 1em 1em;
  background-color: var(--gray-100);
  margin: 0 -1em;

  article & p {
    margin: 0;
    text-indent: 0;
  }
}

article {

  ul, ol {
    list-style: initial;
    padding-left: 2em;
  }

  ol {
    list-style: decimal;
  }

  p {
    margin: 1rem 0;
  }
}

a, a:visited {
  color: inherit;
}


article img {
  width: 100%;
}

code, pre {
  font-size: 1rem;
  border-radius: 0.4em;
}

pre {
  overflow-x: auto;
  padding: 0.5em;
  margin: 0 -0.5em;
}

p code {
  background-color: var(--gray-200) !important;
  padding: 0.1em 0.2em;
}

.measure {
  width: 100%;
  max-width: 36rem;
}

.center {
  justify-self: center;
}

.end {
  justify-self: end;
}

.caps {
  font-size: 0.85em;
  text-transform: uppercase;
}

.respo {
  font-size: clamp(1.75rem, 3.8vw, 2.8rem);
}

.caption {
  width: 100%;
  text-align: center;
  opacity: 0.8;
  font-size: 0.85em;
  margin: 0.2em 0;
}
