.link-index {
  font-size: 1.125rem;

  .page & {
    display: none;

    @include media($bp-md) {
      display: block;
    }
  }

  .tall {
    margin: 2em 0;
  }

  @include media($bp-md) {
    display: block;
    font-size: 1rem;
    overflow-y: auto;
    border-right: 1px solid var(--gray-200);
    flex: 1;
    padding-bottom: 4rem;
    padding-right: 2rem;
    grid-row: 2;
    height: calc(100vh - var(--header-height));
    position: fixed;
    width: 16rem;
    top: var(--header-height);
  }

  a, a:visited {
    text-decoration: none;
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  @include media ($bp-lg) {
    font-size: 0.888rem;
  }
}

.link-index__heading {
  font-size: 1.25rem;
  font-weight: 700;
  line-height: 1;
  margin-bottom: 0.5rem;

  &:not(:first-child) {
    margin-top: 3rem;
  }
}
