.wide-image {
  width: 100vw;
  margin-left: calc( -1 * var(--margin) );

  img {
    width: 100%;
  }

  @include media($bp-md) {
    width: 132%;
    margin-left: -16%;
    margin-right: -16%;
  }
}
